
import Vue from "vue";
import datasets, { Dataset as DatasetType } from "@/store/dataset";
import { Component } from "vue-property-decorator";

@Component
export default class Dataset extends Vue {
  name = "Dataset";

  get contents(): DatasetType[] {
    return this.$i18n.locale === "zh" ? datasets.zh : datasets.en;
  }

  get cardHeight(): number {
    if (this.$vuetify.breakpoint.xl) return 450;
    else return 350;
  }

  tryJumpToHref(content: DatasetType): void {
    if (content.href) window.location.href = content.href
  }
}
