
import Vue from "vue";
import { Component } from "vue-property-decorator";
import S404 from "@/views/404.vue";
import Markdown from "@/components/Markdown.vue";
import datasets, { Dataset } from "@/store/dataset";

@Component({
  components: { Markdown, S404 },
})
export default class DatasetDetail extends Vue {
  name = "DatasetDetail";

  get dataset(): Dataset | undefined {
    const d = this.$i18n.locale === "zh" ? datasets.zh : datasets.en;
    return d.find((v) => v.name === this.$route.params.name);
  }
}
